import axios from "axios";
import { useEffect, useState } from "react";
import Helpers from "../../../Config/Helpers";
import PageLoader from "../../../Components/Loader/PageLoader";
import { Link } from "react-router-dom";

const UserPricingPlans = () => {
    const [plans, setPlans] = useState([]);
    const [pageLoading, setPageLoading] = useState(false);
    
    const getPlans = () => {
        setPageLoading(true);
        axios.get(`${Helpers.apiUrl}stripe/products`, Helpers.authHeaders).then(response => {
            setPlans(response.data.data);
            setPageLoading(false);
        }).catch(error => {
            Helpers.toast("error", error.response.data.message);
            setPageLoading(false);
        });
    }

    useEffect(() => {
        getPlans();
    }, []);

    return (
        <div class="nk-content">
            <div class="container-xl">
                <div class="nk-content-inner">
                    {pageLoading ? <PageLoader /> : <div class="nk-content-body">
                        <div class="nk-block-head nk-page-head">
                            <div class="nk-block-head-between">
                                <div class="nk-block-head-content">
                                    <h2 class="display-6">Pricing Plans</h2>
                                    <p>Generate unlimited copy 10X faster with our cost effective plan to write blog posts, social media ads and many more.</p>
                                </div>
                            </div>
                        </div>
                        <div class="nk-block">
                            <div class="mt-xl-5">
                                <div class="row g-0">
                                    {plans.map((plan, index) => {
                                        return (
                                            <div key={index} class="col-xl-4">
                                                <div class="card pricing bg-white rounded-start">
                                                    <div class="pricing-content">
                                                        <div class="w-sm-70 w-md-50 w-xl-100 text-center text-xl-start mx-auto">
                                                            <h2 class="mb-3">{ plan.name }</h2>
                                                            <div class="pricing-price-wrap">
                                                                <div class={`pricing-price pricing-toggle-content monthly active`}>
                                                                    <h3 class="display-1 mb-3 fw-semibold">{ parseFloat(plan.unit_amount).toFixed(2) } { plan.currency } <span class="caption-text text-light fw-normal"> / ${ plan.interval }</span></h3>
                                                                </div>
                                                            </div>
                                                            <div class="mb-2">
                                                                {(plan.id === Helpers.authUser.product_id && Helpers.authUser.is_subscription_active) ? <button disabled class="btn btn-outline-light w-100">Already Subscribed</button>
                                                                : <Link to={`/user/subscribe-product/${ plan.id }`} class="btn btn-outline-light w-100">Upgrade Package</Link>}
                                                            </div>
                                                            <ul class="pricing-features">
                                                                {plan.features.map(feature => <li><em class="icon text-primary ni ni-check-circle"></em><span>{ feature.name }</span></li>)}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                            <div class="mt-5">
                                <h5>Want to learn more about our pricing &amp; plans?</h5>
                                <p>Read our <a href="pricing-plans.html">Plans</a>, <a href="#">Billing &amp; Payment</a>, <a href="#">FAQs</a> to learn more about our service.</p>
                            </div>
                        </div>
                    </div>}
                </div>
            </div>
        </div>
    );
}

export default UserPricingPlans;